import { VirtualElement } from "@floating-ui/react";
import { useEffect } from "react";

import { isNative } from "@kraaft/helper-functions";

export function addContextMenuListener(
  ref: React.RefObject<HTMLElement>,
  callback: (virtualElement: VirtualElement) => void,
) {
  const abortController = new AbortController();

  ref?.current?.addEventListener(
    "contextmenu",
    (event) => {
      if (event.ctrlKey || event.shiftKey || event.altKey || event.metaKey) {
        // Do nothing, allow default context menu
        return;
      }
      event.preventDefault();

      const virtualElement: VirtualElement = {
        contextElement: ref?.current || undefined,
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            x: event.clientX,
            y: event.clientY,
            left: event.clientX,
            right: event.clientX,
            top: event.clientY,
            bottom: event.clientY,
          };
        },
      };
      callback(virtualElement);
    },
    {
      signal: abortController.signal,
    },
  );

  return () => {
    abortController.abort();
  };
}

export function useContextMenuListener(
  ref: React.RefObject<HTMLElement> | null,
  callback: (virtualElement: VirtualElement) => void,
) {
  useEffect(() => {
    if (isNative()) {
      return;
    }

    if (!ref?.current) {
      return;
    }

    return addContextMenuListener(ref, callback);
  }, [callback, ref]);
}
