import { VirtualElement } from "@floating-ui/react";
import { useRef } from "react";

import { ContextMenuProps } from "./contextMenu.types";
import { ContextMenuSheetDefinition } from "./contextMenuSheet";
import { useContextMenuListener } from "./useContextMenuListener";

export const ContextMenu = ({
  onOpen,
  onClose,
  isOpen,
  contextAnchor,
  children,
  withoutBackdrop,
  placement,
}: ContextMenuProps) => {
  const popupRef = useRef<VirtualElement | null>(null);

  useContextMenuListener(contextAnchor, onOpen);

  return (
    <ContextMenuSheetDefinition.Host
      open={isOpen}
      onClose={onClose}
      anchor={popupRef}
      withoutBackdrop={withoutBackdrop}
      placement={placement}
    >
      {children}
    </ContextMenuSheetDefinition.Host>
  );
};
