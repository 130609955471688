import React, { RefObject, useCallback, useRef } from "react";

import { ActionSheetItemPropsWithKey } from "../baseContextMenu/contextMenu.types";
import {
  ImperativeActionContextMenu,
  ImperativeContextMenuHandle,
} from "../baseContextMenu/imperativeActionContextMenu";
import { useContextMenuListener } from "../baseContextMenu/useContextMenuListener";

export type UseGlobalActionContextMenuProps = {
  data: Array<ActionSheetItemPropsWithKey>;
  contextAnchor: RefObject<HTMLElement> | null;
};

/**
 * Hook that registers a global context menu.
 * This is the simplest solution to implement a context menu since you don't need to append anything your JSX.
 * To use it, make sure to wrap your app with {@link ContextMenuProvider}
 *
 * Usage
 *
 * ```typescript jsc
 * const YourComponent = () => {
 *  const divRef = useRef<HTMLElement>(null);
 *  useGlobalContextMenu({
 *    data: [{
 *      label: "option 1",
 *      onClick: () => console.log("Hello")
 *    }],
 *    contextAnchor: divRef,
 *  })
 *
 *  return <div ref={divRef}></div>
 * }
 * ```
 *
 *
 * @param options - The options for the context menu.
 * @param options.data - The data to pass to the context menu.
 * @param options.contextAnchor - The element that triggers the context menu.
 *
 * @returns - An object containing the "open" function that opens the context menu.
 */
export function useGlobalActionContextMenu({
  data,
  contextAnchor,
}: UseGlobalActionContextMenuProps) {
  const context = React.useContext(GlobalContextMenuContext);

  useContextMenuListener(contextAnchor, (virtualElement) => {
    context.current?.openWith(data, virtualElement);
  });

  const open = useCallback(
    () => context.current?.openWith(data),
    [context, data],
  );

  return {
    open,
  };
}

const GlobalContextMenuContext = React.createContext<
  React.RefObject<ImperativeContextMenuHandle>
>({
  current: null,
});

/**
 * ContextMenuProvider is a component that provides a context for {@link useGlobalActionContextMenu}.
 */
export const ContextMenuProvider = ({ children }: React.PropsWithChildren) => {
  const imperativeContextMenuRef = useRef<ImperativeContextMenuHandle>(null);
  return (
    <GlobalContextMenuContext.Provider value={imperativeContextMenuRef}>
      {children}
      <ImperativeActionContextMenu ref={imperativeContextMenuRef} />
    </GlobalContextMenuContext.Provider>
  );
};
