import React, { ComponentType } from "react";
import { Platform, PlatformOSType } from "react-native";
import { assert, RequiredKeys, UnionToIntersection } from "ts-essentials";

import { useBooleanState } from "@kraaft/helper-hooks";

import { anchoredSheetDefinition } from "./anchoredSheet/anchoredSheet.definition";
import { bottomSheetDefinition } from "./bottomSheet/bottomSheet.definition";
import { popupSheetDefinition } from "./popupSheet/popupSheet.definition";
import { SheetDefinition } from "./sheet.types";

const sheetFactory = {
  bottom: bottomSheetDefinition,
  anchored: anchoredSheetDefinition,
  popup: popupSheetDefinition,
} satisfies { [variant: string]: SheetDefinition };

type AvailableVariantForPlatform = {
  native: "bottom";
  ios: "bottom";
  android: "bottom";
  web: "anchored" | "popup";
} & { [P in PlatformOSType]: SheetVariant };

type SheetFactoryType = typeof sheetFactory;
type SheetVariant = keyof SheetFactoryType;

type Specifics = { [P in PlatformOSType]?: AvailableVariantForPlatform[P] } & {
  default?: SheetVariant;
};

type ExtractVariant<S extends Specifics> = Extract<S[keyof S], SheetVariant>;

/** @deprecated please use {@link Sheet} API */
export function getSheet<S extends Specifics>(
  specifics: S,
): SheetFactoryType[ExtractVariant<S>] {
  const variantForPlatform = Platform.select(specifics);

  if (variantForPlatform !== undefined) {
    return sheetFactory[variantForPlatform as ExtractVariant<S>];
  }

  assert(false, "sheet variant is not defined for platform");
}

type PropsOf<T> = T extends React.ComponentType<infer P> ? P : never;

export function Sheet<S extends Specifics>(specifics: S) {
  const { Host, ...definition } = getSheet(specifics);

  type HostProps = UnionToIntersection<
    PropsOf<ReturnType<typeof getSheet<S>>["Host"]>
  >;
  type PartialHostProps = Partial<HostProps>;

  // Merge the props of the Host component with the props of the wrapped component
  // Allows for easier usage of the sheets
  function Wrap<P, StaticProps extends PartialHostProps>(
    Component: React.FC<P>,
    staticProps?: StaticProps,
  ) {
    type WrappedComponentProps = HostProps & P;
    type OnlySetKeys = RequiredKeys<StaticProps>;
    type WrappedComponentHookProps = Omit<
      WrappedComponentProps,
      OnlySetKeys | "open" | "onClose" | "children"
    > & {
      [k in keyof StaticProps]?: StaticProps[k] | undefined;
    };

    const WrappedComponent = (props: WrappedComponentProps) => (
      <Host {...(staticProps ?? {})} {...(props as any)}>
        <Component {...(props as any)} />
      </Host>
    );

    WrappedComponent.use = (props: WrappedComponentHookProps) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isOpen, open, close] = useBooleanState();

      return {
        isOpen,
        open,
        close,
        element: (
          <WrappedComponent open={isOpen} onClose={close} {...(props as any)} />
        ),
      };
    };

    WrappedComponent.withDefaults = <Se extends PartialHostProps>(props: Se) =>
      Wrap(Component, props);

    return WrappedComponent;
  }

  // Tool used to create an inlined sheet component
  const Tool = <P = HostProps>(
    create: (p: typeof definition) => React.FC<P & HostProps>,
  ) => {
    return Wrap(create(definition));
  };

  const result = {
    Host: Host as ComponentType<HostProps>,
    Paper: definition.Paper,
    Header: definition.Header,
    Content: definition.Content,
    Footer: definition.Footer,
    Wrap: Wrap,
    create: Tool,
  };

  return result;
}
